// Packages
import { Link, useNavigate } from "react-router-dom";

// Code
const LinksSection = ({ content }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  return (
    <ul className="flex flex-col gap-2 text-white justify-center items-center">
      <h2 className="text-2xl font-semibold py-4 border-b-2 border-white">Useful Links</h2>
      {content.map((item, index) => {
        return item.link ? (
          // External link handled by <Link> or 'window.open'
          <a key={index} href={item.link} target="_blank" rel="noopener noreferrer" className="text-lg">
            {item.label}
          </a>
        ) : (
          // Internal route handled by useNavigate
          <p 
            key={index}
            className="text-lg cursor-pointer"
            onClick={() => navigate(item.path)}
          >
            {item.label}
          </p>
        );
      })}
    </ul>
  );
};

export default LinksSection;
