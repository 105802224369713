// Packages
import { useContext } from "react";
import institutionContext from "../../Context/InstitutionContext";

// Code
const SocialSection = ({ content }) => {
  const { PrimaryColor } = useContext(institutionContext).institutionData;
  console.log("Social Media Links:", content);
  return (
    <div
      className={`flex flex-row items-center px-8 py-2`}
      style={{ backgroundColor: PrimaryColor }}
    >
      <div
        className={`flex flex-row gap-2 items-center justify-center w-32 h-12 px-4 py-2 bg-black rounded-xl`}
      >
        {content.map((item, index) => (
          <a
            key={index}
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.icon}
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialSection;
